* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
}

iframe {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loginContainer {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  /* align-items: center; */
  /* align-content: center; */
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1592150053369-ed15a7f68b92?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3432&q=80");
}

.flexContainer {
  display: flex;
  align-items: center;
  /* background-color: #f44336; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ripple code */
/* button {
  background-color: #09c;
  border: none;
  border-radius: 0.25em;
  color: #fff;
  padding: 1em 2em;
} */

.ripple {
  /* box-sizing: border-box; */
  position: relative;
}

.ripple:before {
  animation: ripple 2s 1s ease-out infinite;
  border: solid 2px #decc8e;
  border-radius: 3em;
  bottom: 0;
  /* box-sizing: border-box; */
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ripple:after {
  animation: ripple 2s 2s ease-out infinite;
  border: solid 2px rgb(225, 230, 232);
  border-radius: 3em;
  bottom: 0;
  /* box-sizing: border-box; */
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ripple:hover:before {
  animation: ripple 2s 2s ease-out infinite;
  border: solid 2px rgb(225, 230, 232);
  border-radius: 3em;
}

.ripple:hover:before {
  animation: ripple 1s 2s ease-out infinite;
  border: solid 2px #decc8e;
  border-radius: 3em;
}

@keyframes ripple {
  0% {
    opacity: 0.25;
  }
  100% {
    border-radius: 2em;
    opacity: 0;
    transform: scale(3);
  }
}
